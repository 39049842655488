import { DateTime } from "luxon";

export const tomorrow = () => {
  return DateTime.local().plus({ days: 1 }).toJSDate();
};

export const getCurrentMondayToNextMonday = () => {
  const currentDate = DateTime.local();
  const currentDayOfWeek = currentDate.weekday;
  const daysToCurrentMonday = (currentDayOfWeek - 1) % 7;
  const currentWeekMonday = currentDate.minus({ days: daysToCurrentMonday });
  const nextWeekMonday = currentWeekMonday.plus({ weeks: 1 });

  return {
    currentWeekMonday: currentWeekMonday.toJSDate(),
    nextWeekMonday: nextWeekMonday.toJSDate(),
  };
};
const shortcutHandlers = {
  today: () => [
    DateTime.local().startOf("day").toJSDate(),
    DateTime.local().endOf("day").toJSDate(),
  ],
  yesterday: () => [
    DateTime.local().minus({ days: 1 }).startOf("day").toJSDate(),
    DateTime.local().minus({ days: 1 }).endOf("day").toJSDate(),
  ],
  last7Days: () => [
    DateTime.local().minus({ days: 6 }).startOf("day").toJSDate(),
    DateTime.local().endOf("day").toJSDate(),
  ],
  currentMonth: () => [
    DateTime.local().startOf("month").toJSDate(),
    DateTime.local().endOf("month").toJSDate(),
  ],
  lastMonth: () => [
    DateTime.local().minus({ months: 1 }).startOf("month").toJSDate(),
    DateTime.local().minus({ months: 1 }).endOf("month").toJSDate(),
  ],
  lastYear: () => [
    DateTime.local().minus({ years: 1 }).startOf("year").toJSDate(),
    DateTime.local().minus({ years: 1 }).endOf("year").toJSDate(),
  ],
  nextMonth: () => [
    DateTime.local().plus({ months: 1 }).startOf("month").toJSDate(),
    DateTime.local().plus({ months: 1 }).endOf("month").toJSDate(),
  ],
  lastQuartal: () => {
    const lastQuarter = DateTime.local().minus({ quarters: 1 });
    return [
      lastQuarter.startOf("quarter").toJSDate(),
      lastQuarter.endOf("quarter").toJSDate(),
    ];
  },
  nextQuartal: () => {
    const nextQuarter = DateTime.local().plus({ quarters: 1 });
    return [
      nextQuarter.startOf("quarter").toJSDate(),
      nextQuarter.endOf("quarter").toJSDate(),
    ];
  },
};

export const getDateRangeFromShortcut = (shortcut) => {
  const handler = shortcutHandlers[shortcut];

  if (handler) return handler();

  return [new Date(), new Date()];
};

export const dateShortcuts = {
  today: "Today",
  yesterday: "Yesterday",
  last7Days: "Last 7 Days",
  lastMonth: "Last Month",
  currentMonth: "Current Month",
  lastYear: "Last Year",
  nextMonth: "Next Month",
  lastQuartal: "Last Quartal",
  nextQuartal: "Next Quartal",
};
